import './Step';
import type Step from './Step';
import type { StepProps } from './Step';

type MultiStepperState = {
    steps: Step[];
    currentStep: number;
    finished: boolean;
};

export type MultiStepperProps = {
    currentStep: string;
    activeFlow: string;
    steps: StepProps[];
};

export default class MultiStepper extends HTMLElement {
    readonly #state: MultiStepperState;

    get state(): MultiStepperState {
        return this.#state;
    }

    get currentStep(): Step {
        return this.#state.steps[this.#state.currentStep - 1];
    }

    get activeFlow(): string | null {
        return this.hasAttribute('active-flow') ? this.getAttribute('active-flow') : null;
    }

    set activeFlow(value: string | null) {
        if (value === null) {
            this.removeAttribute('active-flow');
            return;
        }

        this.setAttribute('active-flow', value);
    }

    constructor() {
        super();

        this.#state = {
            steps: [],
            currentStep: this.hasAttribute('current-step')
                ? Number(this.getAttribute('current-step'))
                : 1,
            finished: false
        };

        this.#goToStep(this.currentStep);
    }

    public reset(): void {
        const firstStep = this.querySelector('nh-step') as Step;
        if (firstStep) {
            this.#goToStep(firstStep);
        }

        this.removeAttribute('current-step');
        this.activeFlow = null;
    }

    public previous(): void {
        if (this.#state.currentStep === 1) return;

        const previousStep = this.#getPreviousStep();
        this.#goToStep(previousStep);
    }

    public async next(): Promise<void> {
        if (!this.currentStep.validate(true)) {
            return;
        }

        const currentStep = this.currentStep;

        const successfulSubmitted = await currentStep.submit();

        if (!successfulSubmitted) {
            return;
        }

        const nextStep = this.#getNextStep();

        if (!nextStep) {
            this.#state.finished = true;
            return;
        }

        this.#goToStep(nextStep, currentStep);
    }

    protected connectedCallback(): void {
        this.#state.steps = Array.from(this.querySelectorAll('nh-step'));
    }

    #getStepIndex(step: Step): number {
        return this.#state.steps.findIndex((s) => step === s);
    }

    #getNextStep(): Step | null {
        const currentStepIndex = this.#getStepIndex(this.currentStep);
        const nextStep: Step | null = this.#state.steps[currentStepIndex + 1] ?? null;

        if (
            !this.activeFlow ||
            nextStep === null ||
            nextStep.flow === null ||
            nextStep.flow === this.activeFlow
        ) {
            return nextStep;
        }

        for (const [index, step] of this.state.steps.entries()) {
            if (index <= currentStepIndex) {
                continue;
            }

            if (step.flow === null || step.flow === this.activeFlow) {
                return step;
            }
        }

        return null;
    }

    #getPreviousStep(): Step {
        const previousStepDefined = this.currentStep.previous;

        if (previousStepDefined) {
            return this.#state.steps[previousStepDefined - 1];
        }

        const index = this.#getStepIndex(this.currentStep);
        return this.#state.steps[index - 1];
    }

    #goToStep(step: Step, current?: Step): void {
        if (!step || step === this.currentStep || !step.step) {
            return;
        }

        this.currentStep.hidden = true;
        this.#state.currentStep = step.step;
        step.hidden = false;

        if (step.flow) {
            this.setAttribute('active-flow', step.flow);
        }

        if (current) {
            step.previous = current.step;
        }
    }
}

if (!window.customElements.get('multi-stepper')) {
    window.customElements.define('multi-stepper', MultiStepper);
}
