import DefaultStepValidationStrategy from './DefaultStepValidationStrategy';
import FormStepValidationStrategy from './FormStepValidationStrategy';
import FlowStepValidationStrategy from './FlowStepValidationStrategy';

type StepValidationStrategyType =
    | typeof DefaultStepValidationStrategy
    | typeof FormStepValidationStrategy
    | typeof FlowStepValidationStrategy;

const stepValidationStrategyTypes: Record<string, StepValidationStrategyType> = {
    default: DefaultStepValidationStrategy,
    form: FormStepValidationStrategy,
    flow: FlowStepValidationStrategy
};

export default stepValidationStrategyTypes;
