import '../../../../src/components/protons/icon/Icon';
import '../../atoms/button/Button';
import type { DefaultComponentType } from '../../../../src/utils/types';
import type { ButtonProps } from '../../atoms/button/Button';
import './Dialog.pcss';

export enum DialogVariant {
    CONFIRMATION = 'confirmation',
    MOBILE_FULL = 'mobile-full',
    ATTACHED = 'attached',
    FULL = 'full'
}

export enum DialogEvents {
    CLOSED = 'closed',
    OPENED = 'opened'
}

export type DialogFooterProps = {
    minimalButton?: ButtonProps;
    secondaryButton?: ButtonProps;
    primaryButton: ButtonProps;
};

export type DeprecatedFooterProps = {
    ctaButton?: ButtonProps;
    otherButton?: ButtonProps;
    cancelButtonText?: string;
};

export type DialogProps = DefaultComponentType &
    DeprecatedFooterProps & {
        id: string;
        title: string;
        is?: string;
        closeButton: boolean;
        backButton: boolean;
        content: string;
        dismissable?: boolean;
        hideFooter?: boolean;
        variant?: DialogVariant;
        footer?: DialogFooterProps;
        clickOutside?: boolean;
    };

export default class Dialog extends HTMLElement {
    public element = this.querySelector('dialog') as HTMLDialogElement;

    #titleElement: HTMLElement | null = this.querySelector('[data-role="title"]');

    readonly #contentElement: HTMLElement | null = this.querySelector('[data-role="content"]');

    #closeButtons: HTMLButtonElement[] = [];

    set title(value: string) {
        if (!this.#titleElement) return;
        this.#titleElement.textContent = value;
    }

    set content(value: string) {
        if (!this.#contentElement) return;
        this.#contentElement.innerHTML = value;
    }

    set variant(variant: DialogVariant | null) {
        if (this.variant === variant) return;

        if (variant) {
            this.setAttribute('variant', variant);
        } else {
            this.removeAttribute('variant');
        }
    }

    get variant(): DialogVariant | null {
        return this.getAttribute('variant') as DialogVariant | null;
    }

    #closeOnClickOutside = this.hasAttribute('click-outside');

    get dismissable(): boolean {
        return this.getAttribute('dismissable') !== 'false';
    }

    set dismissable(dismissable: boolean) {
        this.setAttribute('dismissable', dismissable.toString());
    }

    get closeOnClickOutside(): boolean {
        return this.#closeOnClickOutside;
    }

    set closeOnClickOutside(value: boolean) {
        this.#closeOnClickOutside = value;
        this.toggleAttribute('click-outside', this.#closeOnClickOutside);
    }

    constructor() {
        super();

        if (this.element === null) {
            return;
        }

        Dialog.#polyfillDialog(this.element);

        this.#closeButtons = Array.from(this.querySelectorAll('[data-role="close"]'));
        this.#contentElement = this.querySelector('[data-role="content"]');
    }

    protected clickListener(event: Event): void {
        if (!this.#closeOnClickOutside || !event.target) return;

        if (event.target === this.element) {
            this.close();
        }
    }

    protected connectedCallback(): void {
        if (this.element === null) {
            return;
        }

        this.#closeButtons.forEach((closeButton) => {
            closeButton.addEventListener('click', this.close.bind(this));
        });

        this.element.addEventListener('mousedown', this.clickListener.bind(this));
        this.element.addEventListener('touchstart', this.clickListener.bind(this), {
            passive: true
        });

        this.element.addEventListener('cancel', this.handleCancel.bind(this));
    }

    protected disconnectedCallback(): void {
        this.#closeButtons.forEach((closeButton) => {
            closeButton.removeEventListener('click', this.close.bind(this));
        });

        this.removeEventListener('mousedown', this.clickListener.bind(this));
        this.removeEventListener('touchstart', this.clickListener.bind(this));
        this.removeEventListener('cancel', this.handleCancel.bind(this));
    }

    public show(): void {
        if (this.element.open === true) {
            return;
        }

        this.element.show();

        this.dispatchEvent(new Event(DialogEvents.OPENED, { bubbles: true }));
    }

    public showModal(): void {
        if (this.element.open === true) {
            return;
        }

        this.element.inert = true;
        this.element.showModal();
        this.element.inert = false;

        this.dispatchEvent(new Event(DialogEvents.OPENED, { bubbles: true }));
    }

    protected handleCancel(event: Event): void {
        event.preventDefault();

        if (!this.dismissable) {
            return;
        }

        this.close();
    }

    public close(): void {
        if (!this.element.open) {
            return;
        }

        this.element.classList.add('closing');
        this.element.addEventListener(
            'animationend',
            () => {
                this.element.classList.remove('closing');
                this.element.close();
            },
            { once: true }
        );
        this.dispatchEvent(new Event(DialogEvents.CLOSED, { bubbles: true }));
    }

    static async #polyfillDialog(dialog: HTMLDialogElement): Promise<void> {
        if (typeof dialog.showModal === 'function') {
            return Promise.resolve();
        }

        await import('dialog-polyfill').then((response): void => {
            // eslint-disable-next-line no-prototype-builtins
            const dialogPolyfill = response.hasOwnProperty('default') ? response.default : null;

            if (dialogPolyfill === null) {
                return;
            }

            dialogPolyfill.registerDialog(dialog);
        });

        return Promise.resolve();
    }
}

if (!window.customElements.get('nh-dialog')) {
    window.customElements.define('nh-dialog', Dialog);
}
