import type Step from '../Step';
import { iStepValidationStrategy } from './iStepValidationStrategy';

export default class FormStepValidationStrategy implements iStepValidationStrategy {
    protected step: Step;

    readonly #form: HTMLFormElement;

    public constructor(step: Step) {
        this.step = step;

        this.#form = this.step.querySelector('form') as HTMLFormElement;
    }

    public validate(reportValidity: boolean): boolean {
        const fields: (HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement)[] = Array.from(
            this.#form.querySelectorAll('input, textarea, select')
        );

        this.step.isValid = true;
        for (const field of fields) {
            if (field.validity.valid === false) {
                this.step.isValid = false;
            }
        }

        if (!this.step.isValid && reportValidity) {
            this.#form.reportValidity();
        }

        return this.step.isValid;
    }
}
