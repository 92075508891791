import '../../atoms/multi-stepper/MultiStepper';
/* eslint-disable import/no-duplicates */
import './Dialog';
import Dialog, { DialogVariant } from './Dialog';
/* eslint-enable import/no-duplicates */
import type MultiStepper from '../../atoms/multi-stepper/MultiStepper';

export enum MultiStepDialogEvents {
    FINISHED = 'FINISHED'
}

export default class MultiStepDialog extends Dialog {
    protected multiStepper: MultiStepper | null = this.querySelector('multi-stepper');

    #backButtons: HTMLButtonElement[] = Array.from(this.querySelectorAll('[data-role="back"]'));

    protected nextButton: HTMLButtonElement | null = this.querySelector('[data-role="primary"]');

    #titleElement: HTMLElement | null = this.querySelector('[data-role="title"]');

    #title = this.#titleElement?.innerText || '';

    #nextButtonText = '';

    protected connectedCallback(): void {
        super.connectedCallback();
        if (this.multiStepper === null) return;

        for (const button of this.#backButtons) {
            button.addEventListener('click', this.#previousStep.bind(this));
        }

        this.nextButton?.addEventListener('click', this.nextStep.bind(this));
        this.multiStepper.addEventListener('validate', this.#updateState.bind(this));

        if (this.nextButton) {
            this.#nextButtonText = this.nextButton.innerText;
            this.nextButton.disabled = true;
        }
    }

    protected disconnectedCallback(): void {
        super.disconnectedCallback();
        if (this.multiStepper === null) return;

        for (const button of this.#backButtons) {
            button.removeEventListener('click', this.#previousStep.bind(this));
        }

        this.nextButton?.removeEventListener('click', this.nextStep.bind(this));
        this.multiStepper.removeEventListener('validate', this.#updateState.bind(this));
    }

    public showModal(): void {
        this.#updateState();
        super.showModal();
    }

    #previousStep(): void {
        if (this.multiStepper === null) return;
        this.multiStepper.previous();
        this.#updateState();
    }

    public async nextStep(): Promise<void> {
        if (this.multiStepper === null) return;

        await this.multiStepper.next();
        const { finished } = await this.multiStepper.state;

        if (finished) {
            this.close();
            this.dispatchEvent(new Event(MultiStepDialogEvents.FINISHED));
            return;
        }

        this.#updateState();
    }

    #updateState(): void {
        if (this.multiStepper === null || this.nextButton === null) {
            return;
        }

        const currentStep = this.multiStepper.currentStep;
        if (!currentStep) {
            return;
        }

        this.nextButton.innerText = this.#nextButtonText;

        if (currentStep.continueText) {
            this.nextButton.innerText = currentStep.continueText;
        }

        this.nextButton.disabled = !currentStep.isValid;
        if (this.#titleElement !== null) {
            this.#titleElement.innerHTML = currentStep.stepTitle || this.#title;
        }

        const isFirstStep = this.multiStepper.state.currentStep === 1;

        this.variant = isFirstStep ? null : DialogVariant.MOBILE_FULL;

        for (const button of this.#backButtons) {
            button.hidden = isFirstStep;
            button.disabled = isFirstStep;
        }
    }
}

if (!window.customElements.get('multi-step-dialog')) {
    window.customElements.define('multi-step-dialog', MultiStepDialog);
}
