import FormStepValidationStrategy from './FormStepValidationStrategy';
import type MultiStepper from '../MultiStepper';
import type Step from '../Step';

export default class FlowStepValidationStrategy extends FormStepValidationStrategy {
    readonly #form: HTMLFormElement;

    constructor(step: Step) {
        super(step);

        this.#form = this.step.querySelector('form') as HTMLFormElement;

        if (!this.#form) {
            throw new Error('Please provide a form with flows to choose from.');
        }

        const flowChoices = this.#form.querySelectorAll('input[type="radio"], select');

        if (flowChoices && flowChoices.length <= 0) {
            throw new Error('Please provide a form with flows to choose from.');
        }

        const flow = new FormData(this.#form).get('flow');

        const multiStepper: MultiStepper | null = this.#form.closest('multi-stepper');

        if (multiStepper === null || flow === null) {
            return;
        }

        multiStepper.activeFlow = flow.toString();
    }
}
