import type Step from '../Step';
import { iStepValidationStrategy } from './iStepValidationStrategy';

export default class DefaultStepValidationStrategy implements iStepValidationStrategy {
    protected step: Step;

    constructor(step: Step) {
        this.step = step;
    }

    validate(): boolean {
        return true;
    }
}
